<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="head">
        <div class="head_title">
          {{
            !invalid
              ? "医生给您开的处方"
              : data.order_group_id
              ? "处方单已支付"
              : "处方单已失效"
          }}
        </div>
        <div class="head_sub" v-if="!invalid">
          <span>请核实处方并支付 剩余</span
          ><van-count-down :time="time" @finish="count_change" />
        </div>
        <div class="head_sub" v-if="invalid">如您需再次购买需重新联系医生</div>
      </div>
      <div class="center">
        <div
          :class="['cards flex', {bordered: address_class === true}]"
          @click="change_location"
        >
          <div class="cards_left">
            <div class="cards_title">
              {{
                data.default_address
                  ? data.default_address.address
                  : "请设置您的收货地址"
              }}
            </div>
            <div class="cards_sub">
              {{ data.default_address ? data.default_address.recipient : "" }}
              {{ data.default_address ? data.default_address.phone : "" }}
            </div>
          </div>
          <div class="cards_right" style="min-width: 0">
            <van-icon name="arrow" />
          </div>
        </div>

        <div class="cards">
          <div class="cards_head flex">
            <div class="cards_head-title">药物</div>
            <div class="cards_head-tag">最快20分钟送达</div>
          </div>
          <div class="cards_center">
            <div
              class="cards_item flex"
              v-for="(item, idx) in data.drug"
              :key="idx"
            >
              <div class="cards_left">
                <div class="cards_title">
                  {{ item.standard_name }} {{ item.name }} {{ item.drug_type }}
                  {{ item.specification }}
                </div>
                <div class="cards_sub">
                  {{ item.usage + item.frequency }} 一次{{
                    item.dose + item.dose_unit
                  }}
                </div>
              </div>
              <div class="cards_right">
                <div class="cards_title">
                  ¥{{ format_money(item.price * item.total) }}
                </div>
                <div class="cards_title">{{ item.total + item.unit }}</div>
              </div>
            </div>
          </div>
          <div class="cards_bottom">
            <div class="cards_bottom-item flex">
              <div class="cards_title">药品金额</div>
              <div class="cards_title">¥{{ format_money(price) }}</div>
            </div>
            <div class="cards_bottom-item flex">
              <div class="cards_title">配送费</div>
              <div class="cards_title">¥20.00</div>
            </div>
          </div>
          <div class="cards_sub-bottom flex">
            <div class="cards_title">
              小计：¥{{ format_money(price + 2000) }}
            </div>
          </div>
        </div>

        <div class="cards" v-if="data.advice">
          <div class="cards_head-title">医嘱</div>
          <div class="cards_box">
            <div class="cards_title">{{ data.advice }}</div>
          </div>
        </div>

        <div class="cards">
          <div class="cards_head-title">处方信息</div>
          <div class="cards_box">
            <div class="cards_bottom-item flex">
              <div class="cards_title">申请医生</div>
              <div class="cards_title">{{ data.review_doctor }}</div>
            </div>
            <div class="cards_bottom-item flex">
              <div class="cards_title">审方药剂师</div>
              <div class="cards_title">{{ data.apply_doctor }}</div>
            </div>
            <div class="cards_bottom-item flex">
              <div class="cards_title">申请时间</div>
              <div class="cards_sub">
                {{ format_date(data.apply_date, "yyyy-MM-dd HH:mm:ss") }}
              </div>
            </div>
            <div class="cards_bottom-item flex">
              <div class="cards_title">审核时间</div>
              <div class="cards_sub">
                {{ format_date(data.review_date, "yyyy-MM-dd HH:mm:ss") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom flex">
        <div class="left">
          <div class="price">¥{{ format_money(price + 2000) }}</div>
          <div class="bottom_sub">总金额</div>
        </div>
        <div class="right">
          <div class="bottom_btn" v-if="!invalid" @click="btn_click">
            自费支付
          </div>
          <div class="bottom_btn" v-if="!invalid" @click="btn_click">
            医保支付
          </div>
          <div class="bottom_btn invalid" v-if="invalid">
            {{ data.order_group_id ? "已支付" : "已失效" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { info, confirm_order, pay_order } from "./service";
import { format_date, format_money } from "../../utils/format";
export default {
  data() {
    return {
      time: 1000 * 60,
      invalid: false,
      loading: false,
      id: null,
      order_id: null,
      data: {},
      price: 0,
      address_class: false,
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    this.id = this.$route.query.id;
    this.order_id = this.$route.query.order_id;
    this.get_info();
  },
  methods: {
    count_change() {
      this.invalid = true;
    },
    format_date,
    format_money,
    async get_info() {
      this.loading = false;
      try {
        const { data } = await info({ id: this.order_id });
        this.price = 0;
        data.drug.forEach((el) => {
          this.price += el.total * el.price;
        });
        this.data = data;
        //data.order_group_id = 3
        if (data.order_group_id || data.expire_time < data.current_time) {
          this.invalid = true;
        } else {
          this.time = data.expire_time - data.current_time;
        }
      } finally {
        this.loading = false;
      }
    },
    async btn_click() {
      if (!this.data.default_address) {
        this.$notify({ type: "warning", message: "请添加收货地址" });
        //this.change_location()
        this.address_class = true;
        return;
      }
      let order = [];
      let obj = {
        order_type: 4,
        items: [],
      };

      this.data.drug.forEach((item) => {
        obj.items.push({
          standard_id: item.id,
          standard_name: item.name,
          price: item.price,
          count: item.total,
        });
      });

      order.push(obj);

      let prama = {
        orders: [...order],
        pay_by_self_amount: this.price,
        pay_by_medical_insurance: 0,
        pay_third_part_amount: 0,
        total_amount: this.price,
        drug_program_id: this.order_id,
      };

      this.loading = true;
      try {
        const { data } = await confirm_order(prama);
        //this.clearState();
        //this.$router.push({ path: "/pay", query: { id: data.order_group_id } });
        await pay_order(data.order_group_id);
        this.$notify({ type: "success", message: "支付成功" });
        let that = this;
        setTimeout(() => {
          that.$router.push({
            path: "/online-chat",
            query: { id: this.id },
          });
        }, 500);
      } finally {
        this.loading = false;
      }
    },
    change_location() {
      this.$router.push({
        path: "/my-location",
        query: { ...this.$route.query },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.g_page_box {
  background: #f2f7f8;
  padding-bottom: 60px;
}

.head {
  padding: 15px 16px;
  background: url("../../assets/img/family-bg.png") right center no-repeat
    #000ca8;
  background-size: contain;
  color: #ffffff;
  height: 94px;
}

.head_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.head_sub {
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

>>> .van-count-down {
  color: #fff;
  font-size: 12px;
  line-height: 17px;
}

.center {
  margin: -16px 16px 0;
  position: relative;
}

.cards {
  padding: 14px 10px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 14px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cards_title {
  font-size: 14px;
  line-height: 20px;
  color: #292929;
}

.cards_sub {
  font-size: 12px;
  line-height: 20px;
  color: #919191;
}

.cards_head {
  padding: 3px 0;
}

.cards_head-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #3d3d3d;
}

.cards_head-tag {
  padding: 0 7px;
  height: 17px;
  background: #01e7a9;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  color: #ffffff;
  text-align: center;
}

.cards_center {
  padding: 17px 0 6px 0;
  border-bottom: solid 1px rgba(112, 112, 112, 0.09);
}

.cards_item {
  margin-bottom: 20px;
}

.cards_bottom {
  padding: 18px 0;
  border-bottom: solid 1px rgba(112, 112, 112, 0.09);
}

.cards_bottom-item {
  margin-bottom: 5px;

  & .cards_title {
    font-weight: 400;
  }
}

.cards_sub-bottom {
  padding-top: 14px;
  justify-content: flex-end;
}

.cards_box {
  padding-top: 10px;
}

.bottom {
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  background: #fff;
  padding: 10px 10px 0 20px;
}

.right {
  display: flex;
  align-items: center;
}

.price {
  font-size: 22px;
  font-weight: 600;
  color: #ff6600;
}

.bottom_sub {
  font-size: 12px;
  line-height: 17px;
  color: #707070;
}

.bottom_btn {
  height: 36px;
  background: #1890ff;
  border-radius: 18px;
  line-height: 36px;
  padding: 0 17px;
  margin-left: 7px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;

  &.invalid {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.6);
    }
  }
}

.cards_right {
  min-width: 80px;
  padding-left: 10px;
}
</style>
